import { FC } from 'react';
// components
import PageLayout from 'src/components/PageLayout';
import Section from 'src/components/Section';
import Typography from 'src/components/Typography';
import Button from 'src/components/Button';
import TextField from 'src/components/TextField';

const Dev: FC = () => {
  return (
    <PageLayout>
      <Section title='Typography'>
        <Typography variant='h1Home'>H1 Home</Typography>
        <Typography variant='h1'>H1</Typography>
        <Typography variant='h2Number'>H2 Number</Typography>
        <Typography variant='h2'>H2</Typography>
        <Typography variant='h3'>H3</Typography>
        <Typography variant='h4'>H4</Typography>
        <Typography variant='h5'>H5</Typography>
        <Typography variant='body'>Body</Typography>
      </Section>
      <Section title='Buttons'>
        <Button text='Primary button' variant='primary' />
        <Button text='Secondary button' variant='secondary' />
        <Button text='Attach file' variant='file' />
      </Section>
      <Section title='Text fields'>
        <TextField placeholder='Name' Element='input' />
        <TextField
          placeholder="What's your project about?"
          Element='textarea'
        />
      </Section>
    </PageLayout>
  );
};

export default Dev;
